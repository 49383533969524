import React from 'react'
import { useToast } from '@chakra-ui/react'
import { ERROR_TOAST } from '../../constants'
import { addHours, isValid, getTime } from 'date-fns'
import {
  Enum_Shutdown_Type,
  GetFleetInformationQuery,
  GetMachineActivePercentageQuery,
  GetMapDataQuery,
  GetPreviousShiftsQuery,
  GetShutdownsQuery,
  GetWarningsQuery,
  useUpdateUserMutation
} from 'generated/graphql'
import { map, groupBy, last, first, uniqBy, remove, orderBy, isEqual } from 'lodash'
import { useState, useEffect, useRef } from 'react'
import { useAuthContext } from 'context/AuthProvider'

type WebSocketProviderProps = {
  testRef: React.MutableRefObject<any[]>
  statusMessages: any[]
  shutdownMessages: any[]
  geofenceMessages: any[]
  performanceMessages: any[]
  metricMessages: any[]
  webSocket: React.MutableRefObject<WebSocket | undefined>
  mapDataBuffer: GetMapDataQuery | undefined
  updateFleetInformation: (
    newStatuses: [],
    current: GetFleetInformationQuery | undefined
  ) => GetFleetInformationQuery | undefined
  updateMachinePerformance: (
    current: GetMachineActivePercentageQuery | undefined | null
  ) => GetMachineActivePercentageQuery | undefined | null
  updateShiftPerformance: (
    newStatuses: [],
    current: GetPreviousShiftsQuery | undefined
  ) => any | undefined
  setMapDataBuffer: React.Dispatch<React.SetStateAction<GetMapDataQuery | undefined>>
  setShutdownState: React.Dispatch<React.SetStateAction<GetShutdownsQuery | undefined>>
  shutdownState: GetShutdownsQuery | undefined
  setWarningState: React.Dispatch<React.SetStateAction<GetWarningsQuery | undefined>>
  warningState: GetWarningsQuery | undefined
}
const WebSocketContext = React.createContext<Partial<WebSocketProviderProps>>({})

export const useWebsocketContext = (): Partial<WebSocketProviderProps> =>
  React.useContext(WebSocketContext)
type WebSocketProviderInput = {
  connect: boolean
}
const WebSocketProvider: React.FC<WebSocketProviderInput> = ({ connect, children }) => {
  const { user, sessionSite } = useAuthContext()
  const [updateUserMutation] = useUpdateUserMutation()
  const URL = process.env.REACT_APP_WEBSOCKET_URL ?? ''
  const websocketRef = useRef<WebSocket>()
  const toast = useToast()
  const [statusMessages, setStatusMessages] = useState<any[]>([])
  const [shutdownMessages, setShutdownMessages] = useState<any[]>([])
  const [geofenceMessages, setGeofenceMessages] = useState<any[]>([])
  const [performanceMessages, setPerformanceMessages] = useState<any[]>([])
  const [metricMessages, setMetricMessages] = useState<any[]>([])
  const [mapDataBuffer, setMapDataBuffer] = useState<GetMapDataQuery>()
  const [shutdownState, setShutdownState] = useState<GetShutdownsQuery>()
  const [warningState, setWarningState] = useState<GetWarningsQuery>()
  const userId = user?.id

  const initializeConnection = () => {
    const ws = new WebSocket(URL)
    ws.onopen = () => {
      return ws.send(JSON.stringify({ action: 'saveConnection', data: { userId } }))
    }
    websocketRef.current = ws
    return ws
  }

  useEffect(() => {
    if (!connect) {
      return
    }
    const ws = initializeConnection()
    return () => ws.close()
  }, [connect, userId])

  if (websocketRef.current) {
    websocketRef.current.onclose = () => {
      updateUserMutation({
        variables: {
          input: { where: { id: userId ?? '0' }, data: { websocketConnectionId: null } }
        }
      })
      initializeConnection()
    }

    websocketRef.current.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(JSON.parse(event.data))
        if (parsedData.shiftPerformance) {
          setPerformanceMessages((c) => [...c, parsedData.shiftPerformance])
        } else if (parsedData.machinePerformance) {
          setMetricMessages((c) => [...c, parsedData.machinePerformance])
        } else {
          if (parsedData?.updatedStatuses?.length) {
            const statuses = parsedData.updatedStatuses.map((status: any) => {
              return {
                ...status,
                statusTime: getTime(addHours(new Date(status.statusTime), 2))
              }
            })
            updateMapData(statuses)
            setStatusMessages((c) => [...c, statuses])
          }
          if (parsedData?.updatedShutdowns?.length) {
            const newShutdowns = updateAlarms(
              parsedData.updatedShutdowns,
              Enum_Shutdown_Type.Shutdown
            )
            setShutdownState({ getShutdowns: newShutdowns })

            const newWarnings = updateAlarms(
              parsedData.updatedShutdowns,
              Enum_Shutdown_Type.Warning
            )
            setWarningState({
              getWarnings: newWarnings
            })

            setShutdownMessages((c) => [...c, parsedData.updatedShutdowns])
          }
          if (parsedData?.updatedGeoActivity?.length) {
            setGeofenceMessages((c) => [...c, parsedData.updatedGeoActivity])
          }
        }
      } catch (error) {
        toast({ description: 'ERROR IN SOCKET HOOK: \n' + error, ...ERROR_TOAST })
      }
    }
  }

  const updateMapData = (statuses: any) => {
    const latestMachineStatus = groupBy(statuses, 'machine')
    // update map data for live movement
    setMapDataBuffer((currentState) => {
      if (!statuses[0].query.includes('T1') && !statuses[0].query.includes('T5')) {
        return currentState
      }
      const update = currentState?.getMapData?.map((c) => {
        const copy = Object.assign({}, c)
        if (!latestMachineStatus[c?.machine?.id || -1] || !c?.machine?.id) return copy
        let currentStatusTime = new Date(first(copy.dataPackets)?.statusTime ?? 0)
        if (!isValid(currentStatusTime)) {
          currentStatusTime = new Date(parseInt(first(copy.dataPackets)?.statusTime ?? '0'))
        }
        copy.dataPackets = latestMachineStatus[c.machine.id] // assign latest data packets
        return copy
      })
      if (update)
        return {
          getMapData: update
        }
    })
  }

  const updateMachinePerformance = (
    currentPerformance: GetMachineActivePercentageQuery | undefined | null
  ) => {
    if (!metricMessages) return currentPerformance
    const latestUpdate = last(metricMessages)
    const update = currentPerformance?.getMachineActivePercentage?.map((performance) => {
      const copy = Object.assign({}, performance)
      const machineUpdate = latestUpdate.find((update: any) => update.machine === copy?.machine?.id)
      if (!machineUpdate) {
        return copy
      }
      copy.machineActivePercentage = machineUpdate.machineActivePercentage
      return copy
    })
    return { getMachineActivePercentage: update }
  }

  const updateFleetInformation = (
    newStatuses: [],
    currentStatuses: GetFleetInformationQuery | undefined
  ) => {
    const lms = map(groupBy(newStatuses, 'machine'), (element: any[]) => {
      return last(element)
    })
    const latestGeofenceTips = map(
      groupBy(last(geofenceMessages), 'machine'),
      (machineGeofenceUpdates, index) => {
        const tipCount = machineGeofenceUpdates.filter(
          (update) => update.type === 'Tipping' && update.status === 'Enter'
        ).length
        return { machine: parseInt(index), tipCount, geofence: last(machineGeofenceUpdates) }
      }
    ).flat()
    const update = currentStatuses?.getFleetInformation?.map((status) => {
      const update = lms.find((e) => e.machine === status?.machine)
      const geofenceUpdate = latestGeofenceTips.find(
        (tipCount) => tipCount.machine === status?.machine
      )
      const copy = Object.assign({}, status)
      if (geofenceUpdate) {
        copy.tips += geofenceUpdate.tipCount
        copy.geofenceStatus = geofenceUpdate.geofence
      }
      if (!update) {
        return copy
      }
      if (
        update.query.includes('T1') ||
        update.query.includes('T4') ||
        update.query.includes('T5')
      ) {
        copy.statusTime = update.statusTime
        copy.gpsTime = update.gpsTime
        copy.status = update.status
        copy.statusHexCode = update.statusHexCode
        copy.engineHours = update.engineHours.toString()
      } else if (update.query.includes('T3')) {
        copy.fuelLevel = update.fuelLevel
      }
      //  else if (latestMachineStatus.query.includes('T9')) {
      //   //update water pumped // need initial water value to update this
      // }
      return copy
    })
    return { getFleetInformation: update }
  }
  const updateShiftPerformance = (
    newStatuses: any,
    currentPerformance: GetPreviousShiftsQuery | undefined
  ) => {
    const update = currentPerformance?.getPreviousShifts?.map((shifts) =>
      shifts?.map((shift) => {
        const copy = Object.assign({}, shift)
        if (shift?.id != newStatuses.shift_history.id) {
          return copy
        }
        copy.shift_metrics = newStatuses
        return copy
      })
    )
    return { getPreviousShifts: update }
  }

  const updateAlarms = (latestShutdownMessages: any, type: Enum_Shutdown_Type) => {
    const alarmState =
      type === Enum_Shutdown_Type.Shutdown ? shutdownState?.getShutdowns : warningState?.getWarnings
    const alarms = latestShutdownMessages.filter(
      (alarm: any) => alarm.type === type && isEqual(parseInt(alarm.site), sessionSite?.id)
    )
    const resolvedAlarms = remove(alarms, (alarm: any) => alarm.resolved)
    const unresolvedAlarms = alarmState?.filter(
      (shutdown) =>
        !resolvedAlarms.find(
          (resolvedShutdown: any) => resolvedShutdown.machine === shutdown?.machine?.id
        )
    )
    const updatedAlarms = unresolvedAlarms?.concat(alarms)
    return orderBy(uniqBy(updatedAlarms, 'machine.fleetNumber'), 'statusTime', 'desc')
  }

  return (
    <WebSocketContext.Provider
      value={{
        geofenceMessages,
        metricMessages,
        performanceMessages,
        shutdownMessages,
        statusMessages,
        mapDataBuffer,
        shutdownState,
        warningState,
        updateFleetInformation,
        updateMachinePerformance,
        updateShiftPerformance,
        setMapDataBuffer,
        setShutdownState,
        setWarningState
      }}
    >
      {children}
    </WebSocketContext.Provider>
  )
}

export default WebSocketProvider
