import { lazy } from 'react'
import { RouteProps } from 'react-router'

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
}

const Profile = lazy(() => import('../containers/Profile'))
const Login = lazy(() => import('../containers/Login'))
const Register = lazy(() => import('../containers/Register'))
const Onboarding = lazy(() => import('../containers/Onboarding'))
const ConfirmEmail = lazy(() => import('../containers/ConfirmEmail'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const Dashboard = lazy(() => import('../containers/Dashboard'))
const Map = lazy(() => import('../containers/Map'))
const Site = lazy(() => import('../containers/Site'))
const Engineering = lazy(() => import('../containers/Engineering'))
const Reporting = lazy(() => import('../containers/Reporting'))
const ShiftPerformance = lazy(() => import('../containers/ShiftPerformance'))
const Admin = lazy(() => import('../containers/Admin'))
const CompanyAdmin = lazy(() => import('../containers/Admin/CompanyAdminView'))
const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: false,
    path: '/auth/site',
    breadcrumb: 'Select Site',
    component: Site,
    title: 'Select Site'
  },
  {
    exact: false,
    path: '/auth/dashboard',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard'
  },
  {
    exact: false,
    path: '/auth/map',
    breadcrumb: 'Map',
    component: Map,
    title: 'Map'
  },
  {
    exact: false,
    path: '/auth/performance',
    breadcrumb: 'Shift Performance',
    component: ShiftPerformance,
    title: 'Shift Performance'
  },
  {
    exact: false,
    path: '/auth/profile',
    breadcrumb: 'My Profile',
    component: Profile,
    title: 'My Profile'
  }
]
const PRIVATE_STANDALONE_ROUTES: PrivateRouteObject[] = [
  {
    exact: false,
    path: '/auth/dashboard',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard'
  },
  {
    exact: false,
    path: '/auth/profile',
    breadcrumb: 'My Profile',
    component: Profile,
    title: 'My Profile'
  }
]

const PRIVATE_SUPER_ADMIN_ROUTES: PrivateRouteObject[] = [
  {
    exact: false,
    path: '/auth/dashboard',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard'
  },
  {
    exact: false,
    path: '/auth/map',
    breadcrumb: 'Map',
    component: Map,
    title: 'Map'
  },
  {
    exact: false,
    path: '/auth/performance',
    breadcrumb: 'Shift Performance',
    component: ShiftPerformance,
    title: 'Shift Performance'
  },
  {
    exact: false,
    path: '/auth/admin',
    breadcrumb: 'Administration',
    component: Admin,
    title: 'Administration'
  },
  {
    exact: false,
    path: '/auth/profile',
    breadcrumb: 'My Profile',
    component: Profile,
    title: 'My Profile'
  }
]
const ENGINEERING_ROUTE: PrivateRouteObject = {
  exact: false,
  path: '/auth/engineering',
  breadcrumb: 'Engineering',
  component: Engineering,
  title: 'Engineering'
}
const REPORTING_ROUTE: PrivateRouteObject = {
  exact: false,
  path: '/auth/reporting',
  breadcrumb: 'Reporting',
  component: Reporting,
  title: 'Reporting'
}
const PRIVATE_COMPANY_ADMIN_ROUTES: PrivateRouteObject[] = [
  {
    exact: false,
    path: '/auth/dashboard',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard'
  },
  {
    exact: false,
    path: '/auth/map',
    breadcrumb: 'Map',
    component: Map,
    title: 'Map'
  },
  {
    exact: false,
    path: '/auth/performance',
    breadcrumb: 'Shift Performance',
    component: ShiftPerformance,
    title: 'Shift Performance'
  },
  {
    exact: false,
    path: '/auth/admin',
    breadcrumb: 'Administration',
    component: CompanyAdmin,
    title: 'Administration'
  },
  {
    exact: false,
    path: '/auth/profile',
    breadcrumb: 'My Profile',
    component: Profile,
    title: 'My Profile'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/',
    component: Login
  },
  {
    exact: true,
    title: 'Register',
    path: '/register',
    component: Register
  },
  {
    exact: true,
    title: 'Confirm Email',
    path: '/confirm-email',
    component: ConfirmEmail
  },
  {
    exact: true,
    title: 'Onboarding',
    path: '/onboarding',
    component: Onboarding
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  }
]

export {
  PUBLIC_ROUTES,
  PRIVATE_ROUTES,
  PRIVATE_SUPER_ADMIN_ROUTES,
  PRIVATE_COMPANY_ADMIN_ROUTES,
  PRIVATE_STANDALONE_ROUTES,
  ENGINEERING_ROUTE,
  REPORTING_ROUTE
}
