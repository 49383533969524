import * as React from 'react'
import { FullScreenHandle, useFullScreenHandle } from 'react-full-screen'
type AppContextType = {
  drawerOpen: boolean
  toggleDrawer: () => void
  companyId: string
  setCompanyId: (id: string) => void
  siteId: string
  setSiteId: (id: string) => void
  showNavbar: boolean
  setShowNavbar: (show: boolean) => void
  fullScreen: FullScreenHandle
  setOnMap: (show: boolean) => void
  onMap: boolean
}

export const AppContext = React.createContext<AppContextType>({
  drawerOpen: false,
  toggleDrawer: () => null,
  companyId: '0',
  setCompanyId: () => null,
  siteId: '0',
  setSiteId: () => null,
  showNavbar: false,
  setShowNavbar: () => null,
  fullScreen: {
    active: false,
    node: { current: null },
    enter: async () => undefined,
    exit: async () => undefined
  },
  setOnMap: () => null,
  onMap: false
})

export const useAppContext = (): AppContextType => React.useContext(AppContext)

const AppProvider: React.FC<{}> = ({ children }) => {
  const fullScreen = useFullScreenHandle()
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [showNavbar, setShowNavbar] = React.useState(false)
  const [onMap, setOnMap] = React.useState(false)
  const [companyId, setCompanyId] = React.useState('0')
  const [siteId, setSiteId] = React.useState('0')

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <AppContext.Provider
      value={{
        drawerOpen,
        toggleDrawer,
        companyId,
        setCompanyId,
        siteId,
        setSiteId,
        showNavbar,
        setShowNavbar,
        fullScreen,
        onMap,
        setOnMap
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
