import {
  Flex,
  IconButton,
  Menu,
  Button,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Select
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { motion, MotionProps } from 'framer-motion'
import * as React from 'react'
import { Maximize2, Minimize2, Settings, ArrowDown, ArrowUp } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps, withRouter } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { useAppContext } from '../../context/AppProvider'
import { useAuthContext } from '../../context/AuthProvider/index'
import { Text } from '../../typography'
import Breadcrumbs from '../Breadcrumbs'
import SideBarButton from '../SideBar/SideBarButton'
import { FullScreenHandle } from 'react-full-screen'
import { Label } from 'components/FormElements/styles'
type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
    screen: FullScreenHandle
  } & MotionProps

type HeaderContProps = SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  }

const HeaderCont = styled(motion.div)<HeaderContProps>`
  ${space};
  ${color};
  top: 0;
  right: 0;
  height: 50px;
  z-index: 1290;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: row;
  opacity: 1;
  justify-content: space-between;
  left: ${(props) => (props.open ? '250px' : '64px')};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const BreadCrumbCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 40em) {
    display: none;
  }
`

const Header: React.FC<HeaderProps> = ({ screen, ...rest }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const history = useHistory()
  const { drawerOpen, toggleDrawer, setShowNavbar, showNavbar } = useAppContext()

  const { user, logout, sessionSite, setSessionSite } = useAuthContext()

  const handleLogout = () => {
    logout && logout()
    history.push('/')
  }
  const [siteValue, selectSiteValue] = React.useState(sessionSite?.id)
  const location = useLocation()
  return (
    <div style={{ padding: 12.7, background: 'transparent' }}>
      <HeaderCont pr={4} pl={drawerOpen ? 'calc(186px + 1rem)' : '1rem'} {...rest}>
        <BreadCrumbCont>
          <Breadcrumbs />
        </BreadCrumbCont>
        {!location.pathname.includes('admin') && (
          <Flex w="25%" justifyContent="space-between" alignItems="center">
            <Label>Site: </Label>
            <Select
              w="88%"
              onChange={(e) => {
                selectSiteValue(e.target.value)
                const site = user?.sites?.find((site) => site?.id == e.target.value)
                if (setSessionSite) setSessionSite(site)
              }}
              value={siteValue}
            >
              {user?.sites?.map((site, index) => (
                <option value={site?.id} key={index}>
                  {site?.name}
                </option>
              ))}
            </Select>
          </Flex>
        )}
        {isTabletOrMobile && (
          <SideBarButton color="black" open={drawerOpen} onClick={toggleDrawer} />
        )}
        <Flex flexDirection="row" zIndex={5000}>
          <Flex flexDirection="row" justifyContent="center" alignItems="center">
            <Text mb={0} mr={4} fontWeight="bold">
              {user?.email}
            </Text>
            <Button
              mt={20}
              h="22px"
              right="0"
              type="button"
              variant="solid"
              position="absolute"
              bg={'white'}
              fontSize={'0.5rem'}
              opacity={1}
              borderRadius={'0 0 50px 50px'}
              onClick={() => {
                setShowNavbar(!showNavbar)
              }}
            >
              {showNavbar ? <ArrowUp size={22} /> : <ArrowDown size={22} />}
            </Button>
            <Menu>
              {({ isOpen }) => (
                <>
                  {isOpen ? setShowNavbar(true) : null}
                  <MenuButton
                    size="sm"
                    as={IconButton}
                    colorScheme="brand"
                    icon={<Settings size="18px" />}
                    onClick={() => setShowNavbar(true)}
                  >
                    Profile
                  </MenuButton>
                  <MenuList mr={3} mt={3}>
                    <MenuGroup title="Account">
                      <MenuItem onClick={() => history.push('profile')}>My Account</MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </MenuGroup>
                  </MenuList>
                </>
              )}
            </Menu>
            {screen.active ? (
              <Minimize2 onClick={screen.exit} cursor="pointer" />
            ) : (
              <Maximize2 onClick={screen.enter} cursor="pointer" />
            )}
          </Flex>
        </Flex>
      </HeaderCont>
    </div>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'white'
}
