import { IToast } from '@chakra-ui/react'
import { UploadFile } from '../generated/graphql'

export const APP_NAME = 'Autolectron'

export const STRAPI_USER_STORAGE_KEY = 'strapi-user'

export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST

// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const SUCCESS_TOAST: IToast = {
  duration: 6000,
  isClosable: true,
  title: 'Success!',
  status: 'success',
  position: 'bottom-right'
}

export const ERROR_TOAST: IToast = {
  duration: 6000,
  title: 'Oops!',
  status: 'error',
  isClosable: true,
  position: 'bottom-right'
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')

export const COUNTRY_CODE = '+27'

export const EMPTY_FILE: UploadFile = {
  id: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  name: '',
  hash: '',
  mime: '',
  size: 0,
  url: '',
  provider: ''
}

export const WARNING_TOAST: IToast = {
  duration: 6000,
  title: 'Oops!',
  status: 'warning',
  isClosable: true,
  position: 'bottom-right'
}

export const GEOFENCES = {
  workshop: '#bd8aff',
  park: '#3ACAFF'
}

export const queryCodes = {
  engineData: 'T1',
  alarmEvents: 'T2',
  oilAndFluidLevels: 'T3',
  ignitionOn: 'T4',
  ignitionOff: 'T5',
  preStartCheckEvents: 'T7',
  operatorMesssages: 'T8',
  weightAndOtherData: 'T9',
  alarmReset: 'T10',
  serviceReset: 'T11'
}

export const circlePath = 'M 8 0 C 8 0 8 8 0 8 C -8 8 -8 0 -8 0 C -8 -8 0 -8 0 -8 C 0 -8 8 -8 8 0z'

export const geofencePath =
  'M 1 0 C 1 0 1 1 0 1 C -1 1 -1 0 -1 0 C -1 -1 0 -1 0 -1 C 0 -1 1 -1 1 0z'

export const hexCodes = {
  ignitionOnNoRPM: '0x00',
  lowOil: '0x01',
  hydrTemp: '0x02',
  engineTemp: '0x03',
  oilTemp: '0x04',
  transTemp: '0x05',
  lowWater: '0x06',
  aux1: '0x07',
  aux2: '0x08',
  overRPM: '0x09',
  other: '0x0a',
  auxAnalogChan: '0x0b',
  hydrLevel: '0x0e',
  lowFuelLevel: '0x0f',
  faultRPM: '0x10',
  faultOil: '0x11',
  faultTemp4: '0x12',
  faultTemp1: '0x13',
  faultTemp2: '0x14',
  faultTemp3: '0x15',
  faultTemp5: '0x16',
  faultCANBus: '0x1e',
  iButtonRemoved: '0x1d',
  idleLimitCutout: '0x1a',
  faultShutdownFail: '0x1c',
  highOilPressure: '0x1f',
  engineRun: '0x40',
  ignitionOff: '0xFE',
  systemOverride: '0xff',
  highRoadSpeed: '0xfd',
  turboCoolDown: '0xfc'
}
