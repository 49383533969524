import * as React from 'react'
import { H4 } from '../../typography'
import Card from '../Card'
import RevealFlex from '../RevealFlex/index'

type EmptyListHandlerProps = {
  title?: string
  subTitle?: string
}

const EmptyListHandler: React.FC<EmptyListHandlerProps> = ({ subTitle }) => {
  return (
    <Card
      p={4}
      width="100%"
      height="100%"
      align="center"
      maxWidth="100%"
      justify="center"
      flexDirection="column"
    >
      <RevealFlex height="100%">
        <H4 fontSize="1rem" textAlign="center">
          {subTitle}
        </H4>
      </RevealFlex>
    </Card>
  )
}

export default EmptyListHandler

EmptyListHandler.defaultProps = {
  title: 'Nothing to see here, yet.',
  subTitle: 'Go ahead and create your first record to get started.'
}
