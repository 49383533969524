import React, { Suspense } from 'react'
import { Home, Map, BarChart2, UserCheck, Activity } from 'react-feather'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { FillLoader } from '../components'
import SideBar from '../components/SideBar'
import PageNotFound from '../containers/PageNotFound'
import { useAuthContext } from '../context/AuthProvider'
import {
  PRIVATE_COMPANY_ADMIN_ROUTES,
  PRIVATE_SUPER_ADMIN_ROUTES,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
  PRIVATE_STANDALONE_ROUTES,
  ENGINEERING_ROUTE,
  REPORTING_ROUTE
} from './routes'
import { FullScreen } from 'react-full-screen'
import { Flex } from '@chakra-ui/react'
import { useAppContext } from 'context/AppProvider'
interface RouteType extends RouteProps {
  component: any
}

export type NavItem = {
  to: string
  title: string
  icon: React.ReactNode
  subMenu?: Array<{ to: string; title: string }>
}

const NAV_ITEMS: NavItem[] = [
  {
    to: `/auth/dashboard`,
    title: 'Dashboard',
    icon: <Home size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/map`,
    title: 'Map',
    icon: <Map size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/performance`,
    title: 'Shift Performance',
    icon: <BarChart2 size={20} color="white" className="sidebar-menu-icon" />
  }
]
const STANDALONE_NAV_ITEMS: NavItem[] = [
  {
    to: `/auth/dashboard`,
    title: 'Dashboard',
    icon: <Home size={20} color="white" className="sidebar-menu-icon" />
  }
]
const ENGINEERING_NAV_ITEM = {
  to: `/auth/engineering`,
  title: 'Engineering',
  icon: <Activity size={20} color="white" className="sidebar-menu-icon" />
}
const REPORTING_NAV_ITEM = {
  to: `/auth/reporting`,
  title: 'Reporting',
  icon: <Activity size={20} color="white" className="sidebar-menu-icon" />
}

const ADMIN_NAV_ITEMS: NavItem[] = [
  {
    to: `/auth/dashboard`,
    title: 'Dashboard',
    icon: <Home size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/map`,
    title: 'Map',
    icon: <Map size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/performance`,
    title: 'Shift Performance',
    icon: <BarChart2 size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: `/auth/admin`,
    title: 'Administration',
    icon: <UserCheck size={20} color="white" className="sidebar-menu-icon" />
  }
]

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  const { isAuthenticating, isAuthenticated } = useAuthContext()
  if (isAuthenticating) {
    return <FillLoader />
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Suspense fallback={<FillLoader color="black" />}>
            <Component {...rest} />
          </Suspense>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest}>
    <Component />
  </Route>
)
const Navigation: React.FC<any> = () => {
  const { user } = useAuthContext()
  const { fullScreen } = useAppContext()
  const AUTHENTICATED_ROUTES =
    user?.role?.name === 'Super Admin'
      ? PRIVATE_SUPER_ADMIN_ROUTES
      : user?.role?.name === 'Company Admin'
      ? PRIVATE_COMPANY_ADMIN_ROUTES
      : user?.role?.name === 'Authenticated'
      ? PRIVATE_ROUTES
      : PRIVATE_STANDALONE_ROUTES
  const AUTHENTICATED_NAV_ITEMS = user?.role?.name.includes('Admin')
    ? ADMIN_NAV_ITEMS
    : user?.role?.name === 'Authenticated'
    ? NAV_ITEMS
    : STANDALONE_NAV_ITEMS
  return (
    <FullScreen handle={fullScreen}>
      <Flex bg="white" direction="column">
        <Router>
          <Suspense fallback={<FillLoader />}>
            <Switch>
              {PUBLIC_ROUTES.map((route) => {
                return <PublicRoute key={route.path} {...route} />
              })}
              <Route
                path="/auth"
                render={() => (
                  <SideBar
                    bg="gray.900"
                    color="white"
                    navItems={
                      user?.engineering
                        ? [...AUTHENTICATED_NAV_ITEMS, ENGINEERING_NAV_ITEM]
                        : user?.reporting
                        ? [...AUTHENTICATED_NAV_ITEMS, REPORTING_NAV_ITEM]
                        : AUTHENTICATED_NAV_ITEMS
                    }
                    hoverColor="gray.700"
                    accentColor="cyan.500"
                    closeOnNavigate={true}
                    screen={fullScreen}
                  >
                    {user?.engineering
                      ? [...AUTHENTICATED_ROUTES, ENGINEERING_ROUTE].map((route) => {
                          return <PrivateRoute key={route.path} {...route} />
                        })
                      : user?.reporting
                      ? [...AUTHENTICATED_ROUTES, REPORTING_ROUTE].map((route) => {
                          return <PrivateRoute key={route.path} {...route} />
                        })
                      : AUTHENTICATED_ROUTES.map((route) => {
                          return <PrivateRoute key={route.path} {...route} />
                        })}
                  </SideBar>
                )}
              />
              <Route render={PageNotFound} />
            </Switch>
          </Suspense>
        </Router>
      </Flex>
    </FullScreen>
  )
}
export default Navigation
